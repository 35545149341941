const colors = {
  babyBlue1: '#F1FBFE',
  babyBlue2: '#AFE5FA',
  babyBlue3: '#6CCFF6',
  babyBlue: '#6CCFF6',
  babyBlue4: '#29B9F2',
  babyBlue5: '#0C93C8',
  sonicSilver1: '#A8A9AF',
  sonicSilver2: '#8E9098',
  sonicSilver3: '#757780',
  sonicSilver: '#757780',
  sonicSilver4: '#222224',
  sonicSilver5: '#222224',
  yellowGreen1: '#C5FF21',
  yellowGreen2: '#B6F700',
  yellowGreen3: '#98CE00',
  yellowGreen: '#98CE00',
  yellowGreen4: '#7AA500',
  yellowGreen5: '#5B7C00',
  youtubeRed1: '#FF6666',
  youtubeRed2: '#FF3333',
  youtubeRed3: '#FF0000',
  youtubeRed: '#FF0000',
  youtubeRed4: '#CC0000',
  youtubeRed5: '#990000',
  facebookBlue1: '#6483C3',
  facebookBlue2: '#476BB6',
  facebookBlue3: '#3B5998',
  facebookBlue: '#3B5998',
  facebookBlue4: '#2F477A',
  facebookBlue5: '#23355B',
  richBlack: '#001011',
  babyPowder: '#FFFFFC',
};

export default colors;
